import '../Styles/Projects.css'
import { useEffect } from 'react';
import React from 'react';
import Complete from './Complete'
import Progress from './Progress'
import Pdf from '../assets/GSFHI-Projects.pdf';
import Header from './Header';

function Projects() {
  const completed = [
    ['2023', 'Waikoloa Family Affordable', '110', '$45.5M', 'CSH'],
    ['2023', 'Meheula Vista IV','75','$19.6M','CCHDC'],
    ['2021','Kahului Lani II','83','$38M','CCHDC'],
    ['2019','Kahului Lani I','82','$34.1M','CCHDC'],
    ['2019','Meheula Vista III','75','$19.9M','CCHDC'],
    ['2018','Ainahau Vista II','62','$21.6M','HHDC'],
    ['2017','Meheula Vista II','75','$17.5M','CCHDC'],
    ['2016','Meheula Vista I','75','$18.5M','CCHDC'],
    ['2015','Hale Mohalu II Family IV','84','$28.7M','CSH'],
    ['2015','Hale Mohalu II Family','84','$23.5M','CSH'],
    ['2014','Hale Mohalu II Senior','163','$41.4M','CSH'],
    ['2011','Hale Wai Vista II','132','$35.6M','HHDC'],
    ['2010','Hale Wai Vista','83','$22.6M','HHDC'],
    ['2008','Mokuola Vista','69','$16.5M','HHI'],
    ['2006','Ainahau Vista','106','$21.5M','HHDC'],
    ['2005','Piikoi Vista','47','$8.8M','HHDC'],
    ['2004','Kinau Vista','62','$10.4M','HHDC'],
    ['2003','Wilder Vista','55','$11M','HHDC'],
    ['2003','Artesian Vista','54','$8.5M','HHDC'],
    ['2001','Kalakaua Vista','80','$12.5M','HHDC'],
    ['2000','Wisteria Vista','91','$14M','HHDC'],
    ['1999','Birch Street Apts','53','$12M','HHDC'],
  ];

  const constructed = [
    ['Koa Vista I Senior','96','$39.1M','HHI'],
  ];

  const financed = [
    ['Koa Vista II Family','97','$39.9M','HHI'],
  ];

  const pending = [
    
  ];

  useEffect(() => window.scrollTo(0, 0), [])


  return (
    <div className='projects-container'>
      <Header clear={false} />
      <div className='projects'>
        <Complete data = {completed}/>
        <Progress title = 'Projects Under Construction' data = {constructed}/>
        <Progress title = 'Upcoming Fully Financed Projects' data = {financed}/>
        {/* <Progress title = 'Upcoming Projects (Financing Pending)' data = {pending}/> */}
        <div className='more-project'>
          <div className='more-container'>
            <div>Open Link:   </div>  
            <a className='more-link' href={Pdf} target='_blank' rel='noopener noreferrer'>
              PROJECT DETAILS
            </a>
          </div>
          
        </div>
      </div>
      
    </div>
  )
}

export default Projects
import '../Styles/Header.css';
import React from 'react';
import { Link } from "react-router-dom";

function Header(props) {


    return (
        <div className={props.clear ? 'header-cont' : 'header-cont-bg'}>
            <div className='header'>
                <div className='header-title'>
                    GSF LLC
                </div>
                <div className='header-menu'>
                    <Link className='header-itm' to='/'>
                        <div className='link'>Home</div>
                    </Link>
                    <Link className='header-itm' to='/about'>
                        <div className='link'>About</div>
                    </Link>
                    <Link className='header-itm' to='/projects'>
                        <div className='link'>Projects</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header
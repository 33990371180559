import '../Styles/Complete.css'
import React from 'react'

function Complete(props) {
  return (
    <div className='complete-container'>
      <div className='complete-title'>
        Completed Projects
      </div>
      <div className='complete-body'>
        <table className='projects-table'>
          <tr>
            <th>Year</th>
            <th>Projects</th>
            <th>Units</th>
            <th>Cost</th>
            <th>Non-Profit</th>
          </tr>
          {props.data.map((d) => {
            return (
              <tr>
                {d.map((c) => {
                  return (
                    <td>{c}</td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  )
}

export default Complete
import React, { useEffect } from 'react';
import '../Styles/Home.css'
import background from '../assets/bg.png';
import Header from './Header';

function Home() {

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className='home-cont'>
      <Header clear={true} />
      <img src={background} alt="bg" />
      <div className='home-tint'>

      </div>
    </div>
  )
}

export default Home
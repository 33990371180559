import '../Styles/About.css'
import { useEffect } from 'react';
import React from 'react';
import KHL from '../assets/khl.jpg';
import AD from '../assets/ad-img.png';
import Hale from '../assets/HaleMohalu.jpg';
import WFA from '../assets/wfa.jpeg';
import Header from './Header';

function About() {

    useEffect(() => window.scrollTo(0, 0), []) 
    return (
        <div className='about-container'>
            <Header clear={false} />
            <div className='about-us'>
                <div className='about-section'>
                    <div className='square'>
                        <div className='block'>
                            {/* <div className='cover-intro'>
                                <div className='intro-head'>GSF LLC</div>
                                <div className='intro-desc'>Real Estate Development & Consulting</div>
                            </div> */}
                            <div className='cover-intro'>
                                <img src={WFA} alt="" />
                            </div>
                            <div className='cover-intro'>
                                <img src={KHL} alt="" />
                            </div>
                            <div className='cover-intro'>
                                <img src={Hale} alt="" />
                            </div>
                            <div className='cover-intro'>
                                <img src={AD} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-section'>
                    <div className='section-title'>
                        About Us
                    </div>
                    <div className='section-body'>
                        GSF LLC is an affordable housing developer with nearly 50 years
                        of experience. The company frequently partners with non-profit
                        entities like Catholic Charities Housing Development Corporation 
                        (CCHDC), Coalition for Specialized Housing (CSH), Hawaii Housing
                        Development Corporation (HHDC), Homes Hawaii Inc. (HHI) and local builders.
                    </div>
                </div>
                <div className='about-section'>
                    <div className='section-title'>
                        Services
                    </div>
                    <div className='section-body'>
                        Areas Of Expertise Include:
                        <ul>
                            <li>
                                Affordable Housing Development
                            </li>
                            <li>
                                Acquisitions and Development
                            </li>
                            <li>
                                Financing
                            </li>
                            <li>
                                Planning
                            </li>
                            <li>
                                Sales and Asset Management of Real Estate Projects
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='about-section'>
                    <div className='section-title'>
                        Contact
                    </div>
                    <div className='section-body'>
                        <a href = 'mailto: contact@gsfhi.com'>contact@gsfhi.com</a>

                    </div>
                </div>
                <div className='about-section'>
                    <div className='section-title'>
                        People
                    </div>
                    <div className='section-body'>
                        <ul className='section-ul'>
                            <li>
                                Gary S. Furuta - Manager 
                            </li>
                            <li>
                                Andrew B. Furuta - Project Manager
                            </li>
                            <li>
                                Jeffrey B. Furuta - Project Manager
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
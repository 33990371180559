import '../Styles/App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Projects from './Projects';
import RouteChange from './RouteChange';


function App() {
  return (
    <div className='App'>
      <div className='bg'></div>
      <Router>
        <RouteChange>
          <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/about' element={< About />}></Route>
            <Route exact path='/projects' element={< Projects />}></Route>
          </Routes>
        </RouteChange>
      </Router>
    </div>
  );
}

export default App;

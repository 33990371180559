import '../Styles/Progress.css'
import React from 'react';

function Progress(props) {
  return (
    <div className='progress-container'>
        <div className='progress-title'>
            {props.title}
        </div>
        <div className='progress-body'>
        <table className='table-small'>
            <tr>
                <th>Projects</th>
                <th>Units</th>
                <th>Cost</th>
                <th>Non-Profit</th>
            </tr>
            {props.data.map((d) => {
                return (
                <tr>
                    {d.map((c) => {
                        return (
                            <td>{c}</td>
                        );
                    })}
                </tr>
                );
            })}
        </table>
        </div>
    </div>
  )
}

export default Progress